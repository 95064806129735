<template>
  <div class="min-h-screen bg-gray-50 p-6">
    <div class="max-w-7xl mx-auto space-y-2">
      <!-- Header -->
      <div class="flex items-center justify-between pb-2 border-b border-gray-200">
        <h1 class="text-2xl font-semibold text-gray-800">
          Fatura {{ type }}
        </h1>
      </div>
      <!-- Filters Card -->
      <div class="bg-white rounded-lg shadow-sm p-2">
        <div class="grid grid-cols-1 md:grid-cols-4 gap-2">
          <!-- Date Filter -->
          <div class="space-y-0">
            <label for="date" class="block m-0 text-sm font-medium text-gray-700">Data</label>
            <div class="relative">
              <input
                type="date"
                id="date"
                v-model="filters.date"
                :max="max_date_today"
                @change="getData()"
                class="block m-0 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
              <button
                v-if="filters.date"
                @click="filters.date = ''; getData()"
                class="absolute right-1 top-3 text-red-400 hover:text-gray-600 flex items-center"
                type="button"
              >
                <i class="fas fa-times text-xs"></i>
              </button>
            </div>
          </div>

          <!-- Client/Supplier Filter -->
          <div class="m-0">
            <label class="block m-0 text-sm font-medium text-gray-700">
              {{ type === 'Shitje' ? 'Klienti' : 'Furnitori' }}
            </label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <i class="fas fa-search text-gray-400 text-sm"></i>
              </div>
              <input
                type="text"
                v-model="filters.client"
                class="block m-0 w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
              />
            </div>
          </div>

          <!-- Status Filter -->
          <div class="space-y-2">
            <label for="status" class="block m-0 text-sm font-medium text-gray-700">Statusi</label>
            <select
              id="status"
              v-model="filters.status"
              class="block m-0 w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
            >
              <option value="all">Te gjitha</option>
              <option value="paid">Paguar</option>
              <option value="unpaid">Pa paguar</option>
            </select>
          </div>
          <!-- Search Button -->
          <div class="flex justify-end mt-4">
            <button
              @click="getData()"
              class="px-4 py-2 text-indigo-600"
            >
              <i class="fa fa-search mr-2"></i>
              Kërko
            </button>
          </div>
        </div>

      </div>

			<GroupSelector v-model="grouped_columns" :type="route.params.type"  />
      <!-- Table Card -->
      <div class="bg-white rounded-lg shadow-sm overflow-hidden">
        <div v-if="!loading && useProcessStore().processes_invoices.length > 0">
          <!-- Grouped View -->
          <template v-if="grouped_columns.length > 0">
            <div class="p-4">
              <GroupedInvoices
                :groups="grouped_by_invoices"
                :type="type"
              />
            </div>
          </template>
          
          <!-- Regular Table View -->
          <template v-else>
            <div class="overflow-x-auto">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-20">#</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{ route.params.type === 'sale' ? 'POS' : 'Furnitori' }}
                    </th>
                    <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{ route.params.type === 'sale' ? 'Klienti' : 'Magazina' }}
                    </th>
                    <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Vlera</th>
                    <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <i class="fa fa-cog"></i>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="invoice in useProcessStore().processes_invoices" 
                      :key="invoice.invoice_id" 
                      class="hover:bg-gray-50 transition-colors duration-200">
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-400">
											<div class="flex justify-between items-center gap-2">
												<span class="text-xs font-mono">{{ invoice.process_name }}</span>
												<span class="text-black font-bold flex items-center gap-1">
													<span title="Numer Dokumenti">
														{{ invoice.process_number }} 
													</span>
													<span class="fas fa-circle text-gray-400" style="font-size: 4px;"></span> 
													<span title="Numer Fature">
														{{ invoice.invoice_nr }}
													</span>
												</span>
											</div>
											<div class="flex justify-between items-center gap-2">
												<span class="text-xs font-mono">Fiskalizuar</span>
												<span v-if="invoice.fis_processed_at" class="text-green-500 ml-2">
													<i class="fa fa-check"></i> 
												</span>
												<span v-else class="text-red-500 ml-2">
													<i class="fa fa-times"></i> 
												</span>
											</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
											{{ formatDate(invoice.completed_at) }} 
											<span class="font-mono text-xs text-gray-500">
												{{ formatHour(invoice.completed_at) }}
											</span>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
											<div class="flex flex-col justify-start">
												<div>
													{{ route.params.type === 'sale' ? invoice.pos_name : invoice.supplier_name }}
												</div>
												<div class="font-xs text-gray-500">
													<i class="fa fa-user mr-1 fa-xs"></i>
													{{ invoice.created_by }}
												</div>
											</div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {{ route.params.type === 'sale' ? invoice.customer_name : invoice.warehouse_name }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-bold text-black text-right">
                      {{ numeral(invoice.document_price).format('0,0.00') }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      <div class="flex justify-center space-x-3">
                        <a :href="'/process/document/' + invoice.process_id + '/' + invoice.document_id"  class="text-indigo-600 hover:text-indigo-900 transition-colors duration-200">
                          <i class="fas fa-eye"></i>
                        </a>
                        <a :href="'/invoice/' + invoice.invoice_id" class="text-indigo-600 hover:text-indigo-900 transition-colors duration-200">
                          <i class="fa fa-print"></i>
                        </a>
												<button 
													@click="selectedInvoice = invoice; showFisModal = true"
													class="text-indigo-600 hover:text-indigo-900 transition-colors duration-200" >
													<i class="fa fa-cog"></i> 
												</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </div>

        <!-- Loading State -->
        <div v-else-if="loading" class="py-12 text-center">
          <i class="fa fa-spinner fa-spin text-indigo-600 text-2xl"></i>
        </div>

        <!-- Empty State -->
        <div v-else class="py-12 text-center">
          <span class="inline-flex items-center px-4 py-2 border border-orange-200 rounded-full text-sm text-gray-600">
            <i class="fa fa-info text-gray-400 mr-2"></i>
            Nuk ka fatura {{ type }}
          </span>
        </div>
      </div>
    </div>
		<FisDataModal 
			:show="showFisModal"
			:data="selectedInvoice"
			:title="`FIS Data - Invoice #${selectedInvoice?.process_number}`"
			@close="showFisModal = false"
		/>

  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { useProcessStore } from "@/stores/process"
import { computed, reactive, onMounted, ref } from 'vue'
import Api from '@/services/Api'
import GroupedInvoices from './GroupedInvoices.vue'
import GroupSelector from './GroupSelector.vue'
import FisDataModal from './FisDataModal.vue'
var numeral = require('numeral')

const route = useRoute()
const grouped_columns = ref([])
const loading = ref(false)
const showFisModal = ref(false) 
const selectedInvoice = ref(null)

const type = computed(() => {
  switch (route.params.type) {
    case 'sale':
      return 'Shitje'
    case 'purchase':
      return 'Blerje'
    default:
      return ''
  }
})

// Helper function to get nested value from an object
const getNestedValue = (obj, path) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj)
}

// Helper function to format group labels
const formatGroupLabel = (column, value) => {
  if (!value) return 'N/A'
  
  switch (column) {
    case 'completed_at':
      return formatDate(value)
    case 'created_by':
      return value
    case 'pos_name':
      return value
    case 'customer_name':
      return value
    default:
      return value
  }
}

// Date formatting helper
const formatDate = (dateStr) => {
  if (!dateStr) return ''
  const date = new Date(dateStr)
  return date.toLocaleDateString('sq-AL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })
}
 const formatHour = (dateStr) => {
	if (!dateStr) return ''
	const date = new Date(dateStr)
	return date.toLocaleTimeString('sq-AL', {
		hour: '2-digit',
		minute: '2-digit'
	})
}

// Efficient recursive grouping function
const groupData = (data, columns, depth = 0) => {
  if (depth >= columns.length || !data.length) {
    return data
  }

  const groups = new Map()
  const currentColumn = columns[depth]

  // First pass: create groups
  for (const item of data) {
    const key = getNestedValue(item, currentColumn) || 'N/A'
    if (!groups.has(key)) {
      groups.set(key, {
        key,
        label: formatGroupLabel(currentColumn, key),
        column: currentColumn,
        depth,
        items: [],
        totalAmount: 0,
        count: 0
      })
    }
    const group = groups.get(key)
    group.items.push(item)
    group.totalAmount += Number(item.document_price) || 0
    group.count++
  }

  // Second pass: recursive grouping
  if (depth < columns.length - 1) {
    for (const group of groups.values()) {
      group.items = groupData(group.items, columns, depth + 1)
    }
  }

  return Array.from(groups.values())
}

const grouped_by_invoices = computed(() => {
  const store = useProcessStore()
  if (!grouped_columns.value.length) {
    return store.processes_invoices
  }
  return groupData(store.processes_invoices, grouped_columns.value)
})

const max_date_today = computed(() => {
  const today = new Date()
  return today.toISOString().split('T')[0]
})

const filters = reactive({
  date: '',
  client: '',
  status: 'all',
  type: route.params.type
})

async function getData() {
  if (filters.date > max_date_today.value) {
    filters.date = max_date_today.value
    return
  }
  
  loading.value = true
  try {
    const response = await Api(true).post('processes/invoices', filters)
		useProcessStore().processes_invoices = response.data.map(inv => {
			inv.month = useProcessStore().months[inv.month-1].name
			return inv
		})
  } catch (error) {
    console.error('Error fetching invoices:', error)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  filters.date = max_date_today.value
  getData()
})
</script>
