<template>
  <div class="w-full space-y-1">
    <!-- Label -->
    <label class="block text-sm font-medium text-gray-700 m-0">Grupo sipas</label>

    <!-- Dropdown Trigger -->
    <div class="relative group-selector">
      <button
        @click.prevent="toggleDropdown"
        class="w-full flex items-center justify-between px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-white text-sm text-gray-700 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
      >
        <span>Zgjidh kolonat për grupim</span>
        <i class="fas fa-chevron-down text-gray-500"></i>
      </button>

      <!-- Dropdown Menu -->
      <div
        v-if="isOpen"
        class="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg border border-gray-200 py-1"
      >
        <div class="max-h-48 overflow-y-auto">
          <button
            v-for="column in filteredColumns"
            :key="column.value"
            @click="addColumn(column.value)"
            class="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none transition-colors flex items-center justify-between"
          >
            <span>{{ column.label }}</span>
            <i class="fas fa-plus text-gray-400 opacity-0 group-hover:opacity-100 transition-opacity"></i>
          </button>
        </div>
      </div>
    </div>

    <!-- Selected Groups -->
    <div class="bg-white rounded-md border border-gray-300 p-3 min-h-[120px]">
      <div
        v-if="localValue.length === 0"
        class="flex items-center justify-center text-gray-400 text-sm h-full"
      >
        Nuk ka kolona të zgjedhura për grupim
      </div>

      <draggable
        v-model="localValue"
        class="flex flex-wrap gap-2"
        handle=".drag-handle"
        item-key="value"
        @end="updateOrder"
      >
        <template #item="{ element }">
          <div class="flex items-center justify-between bg-gray-50 p-2 rounded-lg border border-gray-200 group hover:shadow-sm transition-shadow">
            <div class="flex items-center">
              <button class="drag-handle text-gray-400 hover:text-gray-600 cursor-move opacity-0 group-hover:opacity-100 transition-opacity">
                <i class="fas fa-grip-vertical"></i>
              </button>
              <span class="text-sm text-gray-700 px-2">{{ getColumnLabel(element) }}</span>
            </div>
            <button
              @click="removeColumn(element)"
              class="text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 transition-opacity"
            >
              <i class="fas fa-times"></i>
            </button>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted, defineProps, defineEmits } from 'vue'
import draggable from 'vuedraggable'

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  type: String,
})

const emit = defineEmits(['update:modelValue'])

const isOpen = ref(false)
const localValue = ref([...props.modelValue])

// Available columns configuration
const allColumns = [
  { value: 'created_at_date', label: 'Data' },
  { value: 'month', label: 'Muaji' },
  { value: 'created_by', label: 'Operatori' },
  { value: 'pos_name', label: 'Dyqani' },
  { value: 'process_name', label: 'Procesi' },
  { value: 'customer_name', label: 'Klienti' },
  { value: 'supplier_name', label: 'Furnitori' },
  { value: 'warehouse_name', label: 'Magazina' },
  { value: 'status', label: 'Statusi' },
  { value: 'document_price', label: 'Vlera' },
]

// Filter columns based on type
const filteredColumns = computed(() => {
  return allColumns.filter((col) => {
    if (props.type === 'sale') return !['supplier_name'].includes(col.value)
    if (props.type === 'purchase') return !['customer_name', 'pos_name'].includes(col.value)
    return true
  }).filter((col) => !localValue.value.includes(col.value)) // Exclude already selected columns
})

// Get label for a column value
const getColumnLabel = (value) => {
  const column = allColumns.find((col) => col.value === value)
  return column ? column.label : value
}

// Toggle dropdown
const toggleDropdown = () => {
  isOpen.value = !isOpen.value
}

// Add column to selection
const addColumn = (value) => {
  localValue.value.push(value)
  emit('update:modelValue', localValue.value)
  isOpen.value = false
}

// Remove column from selection
const removeColumn = (value) => {
  localValue.value = localValue.value.filter((col) => col !== value)
  emit('update:modelValue', localValue.value)
}

// Update order after drag
const updateOrder = () => {
  emit('update:modelValue', localValue.value)
}

// Watch for external changes
watch(
  () => props.modelValue,
  (newVal) => {
    localValue.value = [...newVal]
  },
  { deep: true }
)

// Close dropdown when clicking outside
const closeDropdown = (e) => {
  if (!e.target.closest('.group-selector')) {
    isOpen.value = false
  }
}

// Add click outside listener
onMounted(() => {
  document.addEventListener('click', closeDropdown)
})

onUnmounted(() => {
  document.removeEventListener('click', closeDropdown)
})
</script>

<style scoped>
.group-selector {
  position: relative;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 4px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #666;
}
</style>
