<template>
  <div v-if="show" class="fixed inset-0 z-50 overflow-y-auto">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!-- Background overlay -->
      <div class="fixed inset-0 transition-opacity" @click="$emit('close')">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <!-- Modal panel -->
      <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="w-full">
              <!-- Modal Header -->
              <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4">
                {{ title }}
              </h3>

              <!-- Tab Navigation -->
              <div class="border-b border-gray-200 mb-4">
                <nav class="-mb-px flex space-x-8">
                  <button 
                    v-for="tab in availableTabs" 
                    :key="tab.id"
                    @click="activeTab = tab.id"
                    :class="[
                      activeTab === tab.id
                        ? 'border-indigo-500 text-indigo-600'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                      'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                    ]"
                  >
                    {{ tab.name }}
                  </button>
                </nav>
              </div>

              <!-- Content -->
              <div class="mt-4">
                <!-- JSON Display -->
                <div class="bg-gray-50 rounded-lg p-4 font-mono text-sm overflow-x-auto max-h-96">
                  <pre v-if="activeTab === 'request' && data.fis_request" class="whitespace-pre-wrap">{{ formatJSON(data.fis_request) }}</pre>
                  <pre v-else-if="activeTab === 'response' && data.fis_response" class="whitespace-pre-wrap">{{ formatJSON(data.fis_response) }}</pre>
                  <pre v-else-if="activeTab === 'error' && data.fis_error" class="whitespace-pre-wrap">{{ data.fis_error }}</pre>
                  <div v-else class="text-gray-500 text-center py-4">No data available</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal Footer -->
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button 
            @click="$emit('close')"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from 'vue'

const props = defineProps({
  show: Boolean,
  data: Object,
  title: String
})

defineEmits(['close'])

const activeTab = ref('request')

const availableTabs = computed(() => {
  const tabs = []
  if (props.data?.fis_request) tabs.push({ id: 'request', name: 'Request' })
  if (props.data?.fis_response) tabs.push({ id: 'response', name: 'Response' })
  if (props.data?.fis_error) tabs.push({ id: 'error', name: 'Error' })
  return tabs
})

const formatJSON = (jsonString) => {
  try {
    const obj = typeof jsonString === 'string' ? JSON.parse(jsonString) : jsonString
    return JSON.stringify(obj, null, 2)
  } catch (e) {
    return jsonString
  }
}
</script>
